<template>
	<div class="teacher">
		<list-template
        :loading="loading"
			:total="total"
			:table-data="tableData"
			:table-config="tableConfig"
			:current-page="page"
			@onSearch="onSearch"
			@onReset="search = null"
			@onChangePage="changeCurrentPage"
			@onHandle="tableHandle"
			hasAdd
			@onAdd="onAdd"
		></list-template>
	</div>
</template>

<script>
import {mapState} from 'vuex'

export default {
	_config:{"route":{"path":"list","meta":{"title":"列表","keepAlive":true}}},
  data() {
		return {
      loading:true,
			// 表格配置
			tableConfig: [
				{prop: 'name', label: '毕业年份'},
				{prop: 'enrollment', label: '入学年份'},
				// {prop: 'admission_time', label: '入学时间'},
				// {prop: 'opening_time', label: '开学时间'},
				// {prop: 'graduation_time', label: '毕业时间'},
				{prop: 'create_time', label: '创建时间',minWidth: '100rem'},
				{
					label: '操作',
					handle: true,
					width: 120,
					render: () => ['编辑']
				}
			],
			search: null,
			total: 0,
			dialogVisible: false,
			// 表格中的数据
			tableData: []
		}
	},
	computed: {
		...mapState(['page'])
	},
	mounted() {
		this.$store.commit('setPage', 1)
		this.getData()
	},
	activated() {
		this.getData()
	},
	methods: {
		// 分页被更改
		changeCurrentPage(e) {
			this.$store.commit('setPage', e)
			this.getData()
		},
		/**
		 * 获取数据
		 */
		getData() {
			let { search, page } = this;
      this.loading = true;
			this.$_axios.get('setup/grade', { params: { ...search, page } }).then(res => {
				let { data } = res.data
				this.tableData = data
				let { total } = this.$tools.getPaginationInfo(res.headers)
				this.total = total
			}).finally(()=>this.loading = false);
		},
		// 新增按钮
		onAdd() {
			this.$router.push('./add')
		},
		// 搜索按钮被点击
		onSearch(val) {
			this.$store.commit('setPage', 1)
			this.search = val
			this.getData()
		},
		// 表格后面操作被点击
		tableHandle(row, handle, index) {
				this.$router.push({ path: './edit', query: { id: row.id } })
		}
	}
}
</script>

<style scoped lang="scss">
.list {
	padding: 24rem 0 0 32rem;
	.search {
		.el-input,
		.el-select {
			width: 240rem;
			margin-right: 24rem;
		}
	}
	.pagination {
		margin-top: 50rem;
	}
}
</style>
